"use client";

import React from "react";
import clsx from "clsx";
import {
	ColumnDef,
	ColumnOrderState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	Row,
	RowData,
	Updater,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table";
import { AnimatePresence, motion } from "framer-motion";

import { Button, ButtonVariant } from "../click/Button";
import { Skeleton } from "../progress/Skeleton";
import { Table } from "../Table";
import { getColumnWidth, getMetaWithDefaults, RowWrapper } from "./utils";
import {
	ColumnMeta,
	DataTableSortDirection,
	DataTableSortState,
	PageInfo,
} from "./types";
import { useDataTableSortHelpers } from "./hooks/internal";

export type { ColumnMeta, DataTableSortState, PageInfo };

export { DataTableSortDirection };

// Re-export createColumnHelper utility
export { createColumnHelper } from "@tanstack/react-table";

export type DataTableProps<
	TData extends RowData,
	TSortField extends string = string,
> = {
	data: TData[];
	columns: ColumnDef<TData, any>[];

	rowHeight?: "sm" | "md" | "lg";
	rowClassName?: string | ((row: TData) => string);
	hasBorder?: boolean;
	hideFooter?: boolean;
	emptyState?: React.ReactNode;
	"data-testid"?: string;

	onRowClick?: (row: TData) => void;
	linkRowTo?: string | ((row: TData) => string | null);
	wrapRowWith?: React.ElementType;

	getRowCanExpand?: (row: Row<TData>) => boolean;
	renderSubComponent?: (props: { row: Row<TData> }) => React.ReactNode;
	expandRowOnClick?: boolean;

	isLoading?: boolean;
	onLoadMore?: () => void;
	isLoadingMore?: boolean;
	pageInfo?: PageInfo;
	loadingRowCount?: number;

	sort?: DataTableSortState<TSortField>;
	onSortChange?: (value: DataTableSortState<TSortField>) => void;

	columnVisibility?: VisibilityState;
	onColumnVisibilityChange?: (
		columnVisibility: Updater<VisibilityState>,
	) => void;

	columnOrder?: ColumnOrderState;
	onColumnOrderChange?: (columnOrder: Updater<ColumnOrderState>) => void;
};

const MotionTableRow = motion(Table.Row);

export function DataTable<
	TData extends Record<string, any>,
	TSortField extends string = string,
>({
	data,
	columns,
	sort,
	onSortChange,
	isLoading = false,
	onLoadMore,
	isLoadingMore = false,
	pageInfo,
	loadingRowCount = 20,
	onRowClick,
	rowHeight = "sm",
	rowClassName = "",
	hasBorder = false,
	hideFooter = false,
	"data-testid": testId,
	emptyState = null,
	getRowCanExpand,
	renderSubComponent = () => null,
	expandRowOnClick = false,
	wrapRowWith,
	linkRowTo,
	columnVisibility,
	onColumnVisibilityChange,
	columnOrder,
	onColumnOrderChange,
}: DataTableProps<TData, TSortField>) {
	const table = useReactTable({
		columns,
		data,
		getRowCanExpand,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		state: {
			columnVisibility,
			columnOrder,
		},
		onColumnVisibilityChange,
		onColumnOrderChange,
	});

	const isLoadingInitial = isLoading && !isLoadingMore;

	const makeTestId = (id: string) => (testId ? `${testId}-${id}` : null);

	const rowModel = table.getRowModel();
	const headerGroups = table.getHeaderGroups();
	const footerGroups = table.getFooterGroups();

	// Only display the footer if footer cells have been defined, and the hideFooter prop is not true
	const shouldShowFooter =
		footerGroups.some(({ headers }) =>
			headers.some((header) => header.column.columnDef.footer !== undefined),
		) && !hideFooter;

	const {
		//
		getIsColumnSortable,
		getIsColumnSorted,
		getSortHandler,
	} = useDataTableSortHelpers({
		state: sort,
		onChange: onSortChange,
	});

	return (
		<div className="w-full">
			<div
				className={clsx(
					hasBorder &&
						"border-muted rounded-md overflow-hidden bg-white border",
				)}
			>
				<Table data-testid={testId}>
					{headerGroups.map((headerGroup) => (
						<Table.Header
							data-testid={makeTestId("header")}
							key={headerGroup.id}
						>
							{headerGroup.headers.map(({ column, ...header }) => {
								const meta = getMetaWithDefaults(column);

								return (
									<Table.Column
										key={header.id}
										data-testid={makeTestId(`header-${header.id}`)}
										style={{ width: getColumnWidth(column) }}
										className={clsx(
											"overflow-hidden",
											meta.align === "left" && "text-left",
											meta.align === "center" && "text-center",
											meta.align === "right" && "text-right",
										)}
										sortable={getIsColumnSortable(column)}
										sorted={getIsColumnSorted(column)}
										onClick={getSortHandler(column)}
										sortDirection={sort?.direction}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													column.columnDef.header,
													header.getContext(),
											  )}
									</Table.Column>
								);
							})}
						</Table.Header>
					))}
					<Table.Body>
						<AnimatePresence exitBeforeEnter>
							{isLoadingInitial ? null : (
								<>
									{rowModel.rows.length === 0 ? (
										<div className="flex min-h-[200px] items-center justify-center">
											{emptyState}
										</div>
									) : (
										rowModel.rows.map((row) => (
											<>
												<RowWrapper
													as={wrapRowWith}
													linkRowTo={linkRowTo}
													row={row.original}
												>
													<MotionTableRow
														key={row.id}
														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														exit={{ opacity: 0 }}
														transition={{ duration: 0.2 }}
														data-testid={makeTestId(`row-${row.original.id}`)}
														onClick={() => {
															onRowClick?.(row.original);
															if (expandRowOnClick) {
																row.toggleExpanded();
															}
														}}
														interactive={!!onRowClick || expandRowOnClick}
														height={rowHeight}
														className={
															typeof rowClassName === "function"
																? rowClassName(row.original)
																: rowClassName
														}
													>
														{row
															.getVisibleCells()
															.map(({ column, ...cell }) => {
																const meta = getMetaWithDefaults(column);

																return (
																	<Table.Cell
																		key={cell.id}
																		data-testid={makeTestId(
																			`cell-${column.id}`,
																		)}
																		style={{ width: getColumnWidth(column) }}
																		className={clsx(
																			"overflow-hidden",
																			meta.truncate && "truncate",
																			meta.align === "left" && "text-left",
																			meta.align === "center" && "text-center",
																			meta.align === "right" && "text-right",
																		)}
																	>
																		{flexRender(
																			column.columnDef.cell,
																			cell.getContext(),
																		)}
																	</Table.Cell>
																);
															})}
													</MotionTableRow>
												</RowWrapper>
												{row.getIsExpanded() && (
													<tr className="flex">
														<td
															className="grow"
															colSpan={row.getVisibleCells().length}
														>
															{renderSubComponent({ row })}
														</td>
													</tr>
												)}
											</>
										))
									)}
								</>
							)}
							{isLoading ? (
								<>
									{Array.from({ length: loadingRowCount }).map((_, index) => (
										<MotionTableRow
											// eslint-disable-next-line react/no-array-index-key
											key={`loading-${index}`}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.3 }}
											height={rowHeight}
										>
											{table.getAllColumns().map((column) => (
												<Table.Cell
													key={column.id}
													style={{ width: getColumnWidth(column) }}
												>
													<Skeleton className="my-[5px] flex h-[13px] items-center rounded-md" />
												</Table.Cell>
											))}
										</MotionTableRow>
									))}
								</>
							) : null}
						</AnimatePresence>
					</Table.Body>
					{shouldShowFooter && (
						<Table.Footer data-testid={makeTestId("footer")}>
							{footerGroups.map((footerGroup) => (
								<Table.Row key={footerGroup.id}>
									{footerGroup.headers.map(({ column, ...header }) => {
										const meta = getMetaWithDefaults(column);

										return (
											<Table.Cell
												key={header.id}
												style={{ width: getColumnWidth(column) }}
												className={clsx(
													meta.truncate && "truncate",
													meta.align === "left" && "text-left",
													meta.align === "center" && "text-center",
													meta.align === "right" && "text-right",
												)}
											>
												{header.isPlaceholder
													? null
													: flexRender(
															column.columnDef.footer,
															header.getContext(),
													  )}
											</Table.Cell>
										);
									})}
								</Table.Row>
							))}
						</Table.Footer>
					)}
				</Table>
			</div>

			{!isLoadingInitial && (pageInfo?.hasNextPage ?? false) && (
				<div className="flex items-center justify-center py-6">
					<Button
						variant={ButtonVariant.OutlinePrimary}
						onClick={onLoadMore}
						isLoading={isLoadingMore}
					>
						Show more
					</Button>
				</div>
			)}
		</div>
	);
}
